<template>
  <Layout :pagetitle="'Meu site'">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Informações do meu website
            </h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" class="was-validated">
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <label for="website_theme" class="form-label">
                        Tema do website
                      </label>
                      <select
                        required
                        v-model="config.theme"
                        class="form-control"
                        id="website_theme"
                      >
                        <option
                          v-for="theme in themes"
                          :key="theme.value"
                          :value="theme.value"
                        >
                          {{ theme.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-12" v-for="(options, field) in formFields" :key="field">
                      <div class="mb-3" v-if="options.label != 'É um Vendedor Legalizado?'" >
                        <label class="form-label">
                          {{ options.label }}
                        </label>
                        <input
                          v-model="config[field]"
                          class="form-control"
                          :placeholder="options.label"
                          :required="options.required"
                          :disabled="!isFieldEnabled(field, options)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <label class="form-label mb-0">
                    Uploads de customização
                  </label>
                  <div
                    v-for="(options, field) in uploadFields"
                    :key="field"
                    class="row"
                    style="border: 1px dashed #999; margin: 0.5rem 0 0.75rem 0.06rem;"
                  >
                    <div class="col-sm-12 col-lg-6 text-center p-3">
                      <div style="margin-bottom: .5rem;">
                        {{ options.label }}
                      </div>
                      <img
                        :src="getImageURL(field, options)"
                        style="max-height: 200px; max-width: 80%;"
                      />
                    </div>
                    <div class="col-sm-12 col-lg-6 p-3">
                      <!--<b-col v-if="!isMobile()">
                        <ImagePreview
                          v-if="isFieldEnabled(field, options)"
                          ref="imagePreview"
                          :customImageProperties="options"
                          :savedImgDataUrl="getImageURL(field, options)"
                          @onUpload="(files) => onUpload(files, field)"
                        />
                        <button
                          v-else
                          class="btn upload-trigger-area"
                          type="button"
                          @click="showUpgradeScreen()"
                        >
                          <div class="dz-message needsclick" style="text-align: center;">
                            <div class="mb-3">
                              <i class="display-4 text-muted bx bx-cloud-upload"></i>
                            </div>
                            <h5>Enviar imagem</h5>
                          </div>
                        </button>
                      </b-col>-->
                      <b-col>
                        <file-uploader
                          v-if="isFieldEnabled(field, options)"
                          class="upload-trigger-area"
                          title="Enviar imagem"
                          :options="options"
                          :on="{
                            success: (file) => onUpload(file, field)
                          }"
                        />
                        <button
                          v-else
                          class="btn upload-trigger-area"
                          type="button"
                          @click="showUpgradeScreen()"
                        >
                          <div class="dz-message needsclick" style="text-align: center;">
                            <div class="mb-3">
                              <i class="display-4 text-muted bx bx-cloud-upload"></i>
                            </div>
                            <h5>Enviar imagem</h5>
                          </div>
                        </button>
                      </b-col>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label">Link para o Banner</label>
                        <input
                          v-model="config['link_banner']"
                          class="form-control"
                          placeholder="Escolha um link para seu banner da página inicial"
                          :required="false"
                          :disabled="!isFieldEnabled('link_banner', { isPremium: true })"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary" type="submit">
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { mask } from 'vue-the-mask'
import { onMounted, ref, watch } from "vue";
import { getProfile, updateProfile } from "./api";
import { isFreeAccount, showUpgradeScreen } from '@/feature-check'
import { Toasts } from "../../../utils/toast-alerts";
// import ImagePreview from '../../../components/website/image-preview.vue'
import FileUploader from '../../../components/file-uploader.vue'

const themes = [
  {
    name: 'Clássico',
    value: 'classic'
  },
  {
    name: 'Claro',
    value: 'light'
  },
  {
    name: 'Oceano',
    value: 'ocean'
  },
  {
    name: 'Rubi',
    value: 'ruby'
  },
  {
    name: 'Âmbar',
    value: 'amber'
  },
  {
    name: 'Tangerina',
    value: 'tangerine'
  },
  {
    name: 'Selva',
    value: 'jungle'
  },
  {
    name: 'Orquídea',
    value: 'orchid'
  },
  {
    name: 'Flor',
    value: 'flower'
  }
];

export default {
  setup() {
    const imagePreview = ref(null)

    const formFields = {
      sitename: {
        label: 'Nome do site',
        required: true
      },
      domains: {
        label: 'Domínios (separados por vírgula)',
        required: true,
        isPremium: true
      },
      home_title: {
        label: 'Título de chamada exibido na página inicial',
        required: false,
        isPremium: true
      },
      home_subtitle: {
        label: 'Subtítulo de chamada exibido na página inicial',
        required: false,
        isPremium: true
      },
      contest_finished_message: {
        label: 'Mensagem exibida quando um sorteio encerrado é visualizado',
        required: false,
        isPremium: true
      },
      contest_finished_link: {
        label: 'Link informativo sobre o sorteio encerrado',
        required: false,
        isPremium: true
      },
      fb_pixel_id: {
        label: 'Facebook Pixel ID (separados por vírgula)',
        required: false,
        isPremium: true
      },
      whatsapp_number: {
        label: 'Whatsapp',
        required: false
      },
      whatsapp_group: {
        label: 'Link do grupo no whatsapp',
        required: false
      },
      is_legal_seller: {
        label: 'É um Vendedor Legalizado?',
        required: false
      }
    }

    const uploadFields = {
      page_icon: {
        label: 'Ícone (favicon) de seu website',
        imgFormat: 'image/x-icon,image/png,image/svg',
        width: 50,
        height: 50,
        isPremium: true
      },
      topbar_logo: {
        label: 'Logo da barra superior',
        imgFormat: 'image/png,image/jpg,image/jpeg',
        width: 160,
        height: 28,
        isPremium: false
      },
      home_banner: {
        label: 'Banner da home page versão desktop',
        imgFormat: 'image/png,image/jpg,image/jpeg',
        width: 1920,
        height: 400,
        isPremium: true
      },
      home_banner_mobile: {
        label: 'Banner da home page versão mobile',
        imgFormat: 'image/png,image/jpg,image/jpeg',
        width: 1080,
        height: 1150,
        isPremium: true
      }
    }

    const formKeys = Object.keys(formFields).reduce((a, c) => {
      a[c] = null
      return a
    }, {})

    const uploadKeys = Object.keys(uploadFields).reduce((a, c) => {
      a[c] = null
      return a
    }, {})

    const config = ref({
      ...formKeys,
      ...uploadKeys
    });

    const isFieldEnabled = (field, options) => {
      if (isFreeAccount()) {
        return !options.isPremium
      }
      return true
    }

    /**
     * Ao selecionar outro tema, verifica se é
     * conta FREE, neste caso somente o tema
     * CLASSIC é aceito
     */
    watch(
      () => config.value.theme,
      (next) => {
        if (isFreeAccount() && next !== 'classic') {
          showUpgradeScreen()
          config.value.theme = 'classic'
        }
      }
    )

    onMounted(() => {
      getProfile().then((data) => {
        if (data.user.config.whatsapp_number === undefined) {
          data.user.config = {
            ...data.user.config,
            whatsapp_number: data.user.whatsapp
          }
        }
        config.value = data.user.config ?? {}
      })
    });

    const formOption = (field, key) => {
      return formFields[field][key]
    }

    async function submit(){
      updateProfile({ config: config.value }).then(() => {
        Toasts('success', 'Configurações do website atualizas!')
      }).catch(() => {
        Toasts('success', 'Ocorreu um erro ao atualizar as informações')
      })
    }

    const onUpload = (files, field) => {
      const images = files.xhr ? JSON.parse(files.xhr.responseText) : files
      const path = images.map(({ cdn_url }) => cdn_url)[0]
      config.value[field] = path
    }

    const getImageURL = (field, options) => {
      if (config.value[field]) {
        return config.value[field]
      }

      return `https://via.placeholder.com/300x51.jpg?text=${options.label}`
    }

    const isMobile = () => {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }

    return {
      themes,
      config,
      submit,
      onUpload,
      formOption,
      formFields,
      uploadFields,
      getImageURL,
      isFieldEnabled,
      showUpgradeScreen,
      imagePreview,
      isMobile
    };
  },
  components: {
    Layout,
    // ImagePreview,
    FileUploader
  },
  directives: {
    mask,
  },
};
</script>

<style scoped>
.upload-trigger-area {
  height: 100%;
  width: 100%;
  background-color: #222;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 1rem;
}
</style>